import { DialogClose } from '@radix-ui/react-dialog'
import { ButtonIcon } from '../../../button-icon'

interface DrawerCloseButtonProps {
  onClick?: () => void
}

export const DrawerCloseButton = ({ onClick }: DrawerCloseButtonProps) => {
  return (
    <DialogClose asChild>
      <ButtonIcon
        icon='fa-solid fa-xmark'
        size='small'
        aria-label='Close drawer'
        onClick={onClick}
      />
    </DialogClose>
  )
}
