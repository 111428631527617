import { tv } from 'tailwind-variants'

export const buttonIcon = tv({
  base: 'flex items-center justify-center transition-colors focus:outline-none',
  variants: {
    size: {
      small: 'h-6 w-6',
      medium: 'h-10 w-10',
      large: 'h-12 w-12',
    },
    outline: {
      true: 'rounded-md border border-neutral-10 bg-neutral-00 text-neutral-secondary hover:bg-neutral-30 active:bg-neutral-40 disabled:opacity-50 disabled:cursor-not-allowed',
      false:
        'rounded-md text-icon-neutral-20 enabled:hover:bg-neutral-30 enabled:active:bg-neutral-40 disabled:text-neutral-disabled',
    },
  },
  defaultVariants: {
    size: 'medium',
    outline: false,
  },
})
