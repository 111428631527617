'use client'

import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Inter } from 'next/font/google'
import { cn } from '~/utils/cn'
import { Button, ButtonAlign, ButtonKind, ButtonStandaloneKind } from '../button'
import { Body, BodyColor, BodySize } from '../typography'
import { Title } from '../typography/components/title'
import { TitleSize } from '../typography/components/title/title.types'
import { DrawerClose, DrawerContent, DrawerFooter, DrawerGroup, DrawerHeader, DrawerTitle } from './drawer-group'
import { DEFAULT_PROPS_CANCEL_BUTTON } from './drawer.constants'
import { DrawerProps } from './drawer.types'

const inter = Inter({ subsets: ['latin'] })

export interface DrawerAPI {
  expand: VoidFunction
  collapse: VoidFunction
}

export const Drawer = forwardRef<DrawerAPI, DrawerProps>(function Drawer(
  {
    isOpen,
    onOpenChange,
    children,
    actions,
    title,
    subtitle,
    successButtonProps,
    standaloneButtonProps,
    secondaryButtonProps,
    fullWidth,
    cancelButtonProps = DEFAULT_PROPS_CANCEL_BUTTON,
    ariaDescribedBy = '',
    onClose,
    contentClassName,
    overlayClassName,
    footerContent,
    hideFooterContent = false,
    endAlignedFooterActions,
    expandable,
    className,
    style,
    onAnimationEnd,
    roundedEdges = false,
  },
  ref,
) {
  const [expanded, setExpanded] = useState(false)
  const { label, hidden: hideSuccessButton, ...successButton } = successButtonProps ?? {}
  const { hidden: hideSecondaryButton } = secondaryButtonProps ?? {}
  const { hidden: hideCancelButton } = cancelButtonProps ?? {}

  const hasSuccessButton = !hideSuccessButton && !!successButtonProps
  const hasSecondaryButton = !hideSecondaryButton && !!secondaryButtonProps
  const hasCancelButton = !hideCancelButton && !!cancelButtonProps

  useImperativeHandle(
    ref,
    () => ({
      expand: () => setExpanded(true),
      collapse: () => setExpanded(false),
    }),
    [],
  )

  const buttonsToShow = [hasSuccessButton, hasSecondaryButton, hasCancelButton].filter(Boolean).length

  return (
    <DrawerGroup
      open={isOpen}
      onOpenChange={(value) => {
        onOpenChange?.(value)

        if (!value) {
          onClose?.()
        }
      }}
      onAnimationEnd={onAnimationEnd}
    >
      <DrawerContent
        asChild
        className={cn(
          inter.className,
          roundedEdges && 'rounded-3xl overflow-hidden mx-6 mt-6 mb-4 h-[calc(100vh-3rem)]',
          !roundedEdges && 'h-screen',
          className,
          fullWidth && 'w-full max-w-full',
        )}
        style={style}
        aria-describedby={ariaDescribedBy ?? 'Drawer Content'}
        expanded={expanded}
        overlayClassName={overlayClassName}
      >
        <div data-vaul-no-drag>
          <DrawerHeader
            actions={actions}
            expandable={expandable}
            expanded={expanded}
            onExpandClick={() => setExpanded(!expanded)}
            className={cn(roundedEdges && 'border rounded-2xl mx-3 mt-3 p-4')}
          >
            <DrawerTitle asChild>
              <div
                data-testid='drawer-heading'
                className='flex flex-col gap-1 text-typography-high-contrast'
              >
                <Title
                  size={TitleSize.small}
                  asChild
                >
                  <span>{title ?? ''}</span>
                </Title>

                {subtitle && (
                  <Body
                    size={BodySize.small}
                    color={BodyColor.secondary}
                    asChild
                  >
                    <span>{subtitle}</span>
                  </Body>
                )}
              </div>
            </DrawerTitle>
          </DrawerHeader>

          <div
            className={cn(roundedEdges ? 'h-[calc(100vh-8rem)]' : 'h-screen', 'overflow-y-auto p-5', contentClassName)}
          >
            {typeof children === 'function' ? children({ expanded }) : children}
          </div>

          {!hideFooterContent && (
            <DrawerFooter className={cn('flex justify-between', roundedEdges && 'border rounded-2xl mx-3 mb-3 p-2')}>
              {footerContent ? (
                <div className='flex w-full'>{footerContent}</div>
              ) : (
                <>
                  {standaloneButtonProps != null && (
                    <div>
                      <Button
                        {...standaloneButtonProps}
                        isStandalone={true}
                        kind={(standaloneButtonProps.kind as ButtonStandaloneKind) || ButtonStandaloneKind.brand}
                      >
                        {standaloneButtonProps.label}
                      </Button>
                    </div>
                  )}

                  <div
                    className={cn('grid w-full grid-cols-2 gap-2', {
                      'grid-cols-3': buttonsToShow === 3,
                      'flex flex-row justify-end': endAlignedFooterActions,
                      'flex flex-row items-end justify-end': standaloneButtonProps != null,
                    })}
                  >
                    <DrawerClose asChild>
                      {hasCancelButton && (
                        <Button
                          {...cancelButtonProps}
                          isStandalone={false}
                          kind={ButtonKind.secondary}
                          align={ButtonAlign.center}
                        >
                          {cancelButtonProps.label}
                        </Button>
                      )}
                    </DrawerClose>
                    {hasSecondaryButton && (
                      <Button
                        {...secondaryButtonProps}
                        isStandalone={false}
                        kind={ButtonKind.secondary}
                        align={ButtonAlign.center}
                      >
                        {secondaryButtonProps.label}
                      </Button>
                    )}
                    {hasSuccessButton && (
                      <Button
                        align={ButtonAlign.center}
                        {...successButton}
                      >
                        {label}
                      </Button>
                    )}
                  </div>
                </>
              )}
            </DrawerFooter>
          )}
        </div>
      </DrawerContent>
    </DrawerGroup>
  )
})
