import { forwardRef } from 'react'
import Link from 'next/link'
import { cn } from '~/utils/cn'
import { ButtonAlign, ButtonKind, ButtonProps, ButtonStandaloneKind, ButtonWidth } from './button.types'
import { standaloneButtonClassName, buttonClassName } from './styles'

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      onClick,
      disabled,
      align = ButtonAlign.center,
      size,
      width,
      children,
      link,
      asChild,
      loading = false,
      startAdornment,
      endAdornment,
      isStandalone = false,
      kind,
      className,
      noWrap = true,
      ...props
    },
    ref,
  ) => {
    const allClassNames = isStandalone
      ? standaloneButtonClassName({
          kind: kind as ButtonStandaloneKind,
          size,
          width,
        })
      : buttonClassName({ kind: kind as ButtonKind, size, width })

    // Create a separate class string for the hover effect
    // This uses the same button styles but only extracts the hover class
    const hoverClassNames = isStandalone
      ? standaloneButtonClassName({
          kind: kind as ButtonStandaloneKind,
          size,
          width,
          className: 'hover-only', // This is just a marker class that won't be used
        })
      : buttonClassName({
          kind: kind as ButtonKind,
          size,
          width,
          className: 'hover-only', // This is just a marker class that won't be used
        })

    // Extract only the hover and active classes from the class string
    const hoverClass = hoverClassNames.split(' ').find((cls) => cls.startsWith('hover:bg-'))

    let finalHoverBgColor: string

    if (hoverClass) {
      // If we found a hover:bg- class, use it (without the 'hover:' prefix)
      finalHoverBgColor = hoverClass.replace('hover:', '')
    } else {
      const allClasses = allClassNames.split(' ')
      const hoverBgRegex = /hover:bg-[a-zA-Z0-9-]+/

      // Find any class that matches our hover:bg pattern
      const foundHoverClass = allClasses.find((cls) => hoverBgRegex.test(cls))

      if (foundHoverClass) {
        finalHoverBgColor = foundHoverClass.replace('hover:', '')
      } else {
        // Fallback to a default if we still can't find a hover background
        finalHoverBgColor = 'bg-gray-100'
      }
    }

    const elementProps = {
      className: cn(allClassNames, 'relative overflow-hidden group transition-colors duration-300', className),
      onClick,
      disabled: disabled || loading,
      'aria-label': props['aria-label'] ?? props.title,
    }
    const content = (
      <>
        <div
          className={cn(
            'absolute inset-0 w-full h-full opacity-100 transform translate-y-full transition-transform duration-300 ease-out pointer-events-none',
            // Apply hover background on hover, but only when not disabled
            `${!disabled && !loading ? 'group-hover:translate-y-0' : ''}`,
            // Use the extracted hover background color
            finalHoverBgColor,
            // Apply active background on active state
            `group-active:bg-opacity-0`,
          )}
          aria-hidden='true'
        ></div>
        <div
          className={cn('relative flex w-full items-center gap-2 z-10', {
            'justify-center': align === ButtonAlign.center || width === ButtonWidth.full,
            'justify-start': align === ButtonAlign.left,
            'justify-end': align === ButtonAlign.right,
            'whitespace-nowrap': noWrap,
          })}
        >
          {startAdornment && (
            <i
              aria-hidden
              aria-label='startAdornment'
              className={cn(startAdornment, 'fa-1x')}
            />
          )}
          <div className='relative w-full'>
            <span className={loading ? 'invisible' : ''}>{text ?? children}</span>
            {loading && (
              <div className='absolute inset-0 flex items-center justify-center'>
                <i
                  aria-hidden
                  aria-label='loader'
                  className={cn('fa-light fa-spinner-scale fa-spin-pulse')}
                />
              </div>
            )}
          </div>
          {endAdornment && (
            <i
              aria-hidden
              aria-label='endAdornment'
              className={cn(endAdornment, 'fa-1x')}
            />
          )}
        </div>
      </>
    )

    if (link) {
      const isExternal = link.startsWith('http')
      return isExternal ? (
        <a
          href={link}
          {...elementProps}
          target='_blank'
          rel='noopener noreferrer'
        >
          {content}
        </a>
      ) : (
        <Link
          href={link}
          {...elementProps}
        >
          {content}
        </Link>
      )
    }

    return asChild ? (
      <div {...elementProps}>{content}</div>
    ) : (
      <button
        ref={ref}
        {...elementProps}
        {...props}
      >
        {content}
      </button>
    )
  },
)
Button.displayName = 'Button'
