import React, { forwardRef } from 'react'
import { buttonIcon } from './button-icon.styles'
import { ButtonIconProps } from './button-icon.types'

/*
Note: Any changes to the button-icon component should be reflected in the accordion-group.tsx file which has a
button that mirrors the button icon but doens't use the button-icon component for matters of hydration.
*/

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ icon, size = 'small', type = 'button', className, label, asChild, outline = false, ...buttonAttributes }, ref) => {
    if (asChild) {
      return (
        <>
          <i className={icon} />
          {label && <span>{label}</span>}
        </>
      )
    }

    return (
      <button
        ref={ref}
        type={type}
        className={buttonIcon({ size, outline, className })}
        {...buttonAttributes}
      >
        <i className={icon} />
        {label && <label>{label}</label>}
      </button>
    )
  },
)

ButtonIcon.displayName = 'ButtonIcon'
