'use client'

import React, { useContext, useMemo, useState, useCallback } from 'react'
import { EmailVerification } from '~/bff/graphql/generated/graphql'
import { CompanyWorkspace, Company } from '~/types/auth/TokenPayload'
import { getUser } from '~/v2/helpers/workspace-helper'

const postFetchUserProvider = async (payload: { subdomain: string }) => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_APP_URL}/api/fetch-user-provider`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
    credentials: 'include',
  })

  return response.json()
}

export interface UserContextProps {
  currentWorkspace: CompanyWorkspace | null
  companies: Company[] | null
  user: Awaited<ReturnType<typeof getUser>> | null
  subdomain: string | null
  isSignedIn: boolean
  primaryEmail: EmailVerification | null
  refetchUserProvider: () => Promise<{
    user: Awaited<ReturnType<typeof getUser>> | null
    currentWorkspace: CompanyWorkspace | null
    companies: Company[] | null
  }>
}

export interface UserProviderProps {
  children: React.ReactNode
  user: Awaited<ReturnType<typeof getUser>> | null
  currentWorkspace: CompanyWorkspace | null
  companies: Company[] | null
  subdomain: string | null
}

export const UserContext = React.createContext<UserContextProps | null>(null)

export const UserProvider = ({
  children,
  user: defaultUser,
  currentWorkspace: defaultWorkspace,
  companies: defaultCompanies,
  subdomain,
}: UserProviderProps) => {
  const [user, setUser] = useState(defaultUser)
  const [currentWorkspace, setCurrentWorkspace] = useState(defaultWorkspace)
  const [companies, setCompanies] = useState(defaultCompanies)

  const primaryEmail = user?.EmailVerification?.find((email) => email.isPrimary) ?? null

  const isSignedIn = primaryEmail?.emailVerified ?? false

  // This function is important because router.push does not trigger a rehydration of the page.
  // So we need to refetch the user provider to get the latest data. On some auth flows
  const refetchUserProvider = useCallback(async () => {
    const response = await postFetchUserProvider({ subdomain: subdomain ?? '' })

    if (response.success === false) {
      setUser(null)
      setCurrentWorkspace(null)
      setCompanies(null)
      return null
    }

    if (response.user) setUser(response.user)
    if (response.currentWorkspace) setCurrentWorkspace(response.currentWorkspace)
    if (response.companies) setCompanies(response.companies)
    return response
  }, [subdomain])

  const contextValue = useMemo(
    () => ({
      currentWorkspace,
      companies,
      user,
      subdomain,
      primaryEmail,
      isSignedIn,
      refetchUserProvider,
    }),
    [currentWorkspace, companies, user, subdomain, isSignedIn, refetchUserProvider],
  )

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext) ?? ({} as UserContextProps)
  if (context == null) {
    throw new Error('useUserContext can only be used within a UserProvider')
  }
  return context
}
