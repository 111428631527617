import { ButtonHTMLAttributes, MouseEventHandler } from 'react'

export enum ButtonKind {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  neutral = 'neutral',
  critical = 'critical',
  combobox = 'combobox',
}

export enum ButtonStandaloneKind {
  brand = 'brand',
  subtle = 'subtle',
  critical = 'critical',
}

/* TODO: Rename this according to Figma (Large | Medium | Small) */
export enum ButtonSize {
  medium = 'medium',
  small = 'small',
  xs = 'xs',
}

export enum ButtonWidth {
  auto = 'auto',
  half = 'half',
  full = 'full',
}

export enum ButtonAlign {
  center = 'center',
  left = 'left',
  right = 'right',
}

type StandaloneButtonKind = {
  kind?: ButtonStandaloneKind
  isStandalone?: true
}

type NormalButtonKind = {
  kind?: ButtonKind
  isStandalone?: false
}

export type ButtonProps = Partial<ButtonHTMLAttributes<HTMLButtonElement>> & {
  text?: string
  onClick?: VoidFunction | MouseEventHandler<HTMLElement>
  disabled?: boolean
  size?: ButtonSize
  title?: string
  link?: string
  width?: ButtonWidth
  asChild?: boolean
  loading?: boolean
  startAdornment?: string
  endAdornment?: string
  icon?: string
  align?: ButtonAlign
  noWrap?: boolean
} & (StandaloneButtonKind | NormalButtonKind)
